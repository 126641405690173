import React from "react";

interface ScrollButtonProps {
  activeButton: string;
  onButtonClick: (elementId: string) => void;
  lastPage: number;
  sectionTitle: string;
  pageTitle: string;
}

const ScrollButton: React.FC<ScrollButtonProps> = ({
  activeButton,
  onButtonClick,
  lastPage,
  sectionTitle,
  pageTitle,
}) => {

  const handleScrollStatusStyle = (activeButton:string ,pageTitle:string, sectionTitle:string, page:number) => {
    let className = "";
    if( activeButton === `${sectionTitle}${page + 1}`){
      if (pageTitle === "History" || pageTitle === "Management")
        className = "ScrollStatusActive DarkScrollStatusActive"; // 다크 active
      else className = "ScrollStatusActive";
    }
    else{
      if (pageTitle === "History" || pageTitle === "Management") className = "DarkScrollStatus"; // 다크 inactive
    }
    return className;
  };


  return (
    <div className="ScrollButton">
      <div className="Wrapper">
        <div
          className={
            pageTitle === "History" || pageTitle === "Management"
              ? "DarkPageName"
              : "PageName"
          }
        >
          {pageTitle}
        </div>
        <div className="ScrollStatusWrapper">
          {Array.from(Array(lastPage), (_, page) => (
            <button
              key={page}
              onClick={() => onButtonClick(`${sectionTitle}${page + 1}`)}
              className={
              handleScrollStatusStyle(activeButton, pageTitle, sectionTitle, page)
              }
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default ScrollButton;