import React from "react";

const Ver2021 = () => {
    return (
        <div className="History2019">
            <div className="Years2019">
                2021
            </div>
            <div className="BoldHis">포체인스<span>(주)</span> 법인 설립</div>
            <div className="His2019">
                AI 솔루션 부서 개설<br />
                ㈜프로카젠과 업무협약(MOU) 체결 <br />
                ㈜액트너랩으로부터 투자 유치 <br />
                기업부설연구소 설립
            </div>
        </div>
    )
}

export default Ver2021;