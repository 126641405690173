import React from "react";
import { SolutionProcedureImg } from "../../asset/img/index";

const SolutionProcedure = () => {
    return (
        <div className="Procedure">
            <span className="Explanation">
                사용자들의 편리성을 생각한 솔루션
            </span>
            <span className="BigText">
                솔루션 연구 & 개발을 위한 협력 과정
            </span>
            <img className="SolutionImg" src={SolutionProcedureImg} />
        </div>
    )
}
export default SolutionProcedure;