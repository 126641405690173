import React from "react";
import {
    Servicess,
    ServiceProcedure
} from "../../components/index";

const Service = () => {
    return (
        <div>
            <Servicess />
            <ServiceProcedure />
        </div>
    )
}
export default Service;