import React, { useEffect } from 'react';
import { BlockChainTeam, DevelopBlockChain } from "../../asset/img/index";

interface NetworkProps {
    setCurrentSection: React.Dispatch<React.SetStateAction<string>>;
}

const Network: React.FC<NetworkProps> = ({ setCurrentSection }) => {

    useEffect(() => {
        setCurrentSection("history");
        return () => {
        };
    }, [setCurrentSection]);

    return (
        <div className='FourchainsClass'>
            <div className='Fourchains'>
                <span className='ShortName'>
                    포체인스의 핵심부서
                </span>
                <span className='CenterName'>
                    블록체인 및 네트워크 연구실
                </span>
                <img src={BlockChainTeam} />
                <span className='DevelopName'>
                    블록체인 및 네트워크 연구실 연구 및 개발 역량
                </span>

                <div className='Develop'>
                    <span className='Bold'>
                        블록체인 구현 및 관련 기술 연구 및 개발
                    </span>
                    <span className='Light'>
                        블록체인 및 네트워크 연구실에서는 포체인스(주) R&D센터에서 이론적으로 연구한 블록체인 기술을 실제 환경에 구현하기 위한<br />
                        연구개발을 진행하고 있고, 독자적으로 다자간 계산(Multi Party Computation)과 키 공유 기술에 대한 연구 및 개발 또한 진행하고 있습니다.
                    </span>
                    <span className='Bold'>
                        보안 네트워크 환경 연구 및 개발
                    </span>
                    <span className='Light'>
                        블록체인 및 네트워크 연구실에서는 자사가 보유하고 있는 암호화 기술의 성능지표를 향상시키기 위한<br />
                        네트워크 프로토콜 개발 및 보안 네트워크 구축에 관련된 연구 개발을 진행하고 있습니다.
                    </span>
                </div>

                <div className='DevelopImg'>
                    <span className='TextDevelop'>
                        블록체인 및 네트워크 연구실 담당역할
                    </span>
                    <img src={DevelopBlockChain} />
                </div>
            </div>
        </div>
    )
}
export default Network; 