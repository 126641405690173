import React, { useEffect } from 'react';
import { PlanBack, DevelopPlan } from "../../asset/img/index";


interface DevelopProps {
    setCurrentSection: React.Dispatch<React.SetStateAction<string>>;
}

const Develop: React.FC<DevelopProps> = ({ setCurrentSection }) => {

    useEffect(() => {
        setCurrentSection("history");
        return () => {
        };
    }, [setCurrentSection]);

    return (
        <div className='FourchainsClass'>
            <div className='Fourchains'> 
                <span className='ShortName'>
                    포체인스의 핵심부서
                </span>
                <span className='CenterName'>
                    마케팅부
                </span>
                <img src={PlanBack} alt="PlanBack"/>
                <span className='DevelopName'>
                    기획 역량
                </span>


                <div className='Develop'>
                    <span className='Bold weightBold'>
                        서비스 기획 및 마케팅 전략 수립
                    </span>
                    <span className='Bold'>
                        #리서치분석 #시장조사 및 분석 #데이터 수집 분석 #마케팅 전략
                    </span>
                    <span className='Light'>
                        시장분석을 통해 서비스 기획, 타겟팅/브랜딩 전략과 운영 프로세스를 수립합니다. <br />
                        서비스화 완료 후, 마케팅 목표와 타임라인을 설정하고 기획한 마케팅 전략을 실행하여 시장 진입과 성과를 극대화합니다.<br />
                    </span>

                    <span className='Bold weightBold'>
                        프로젝트 및 협업 관리
                    </span>
                    <span className='Bold'>
                        #프레젠테이션 #타임라인 설정 #소통 #협력 
                    </span>
                    <span className='Light'>
                        프로젝트의 목표와 범위를 명확히 설정하고, 체계적인 일정을 기획하여 실행합니다. <br />
                        또한, 부서 간 소통과 협력을 촉진하며, 지속적인 프로세스 개선을 통해 프로젝트의 품질을 높이고 목표 달성을 지원합니다.<br />
                    </span>

                    <span className='Bold weightBold'>
                        PR
                    </span>
                    <span className='Bold'>
                        #매체 글쓰기 #트렌드 조사 및 분석 
                    </span>
                    <span className='Light'>
                        대외 커뮤니케이션을 관리하고 포체인스의 브랜드 이미지를 구축 및 유지합니다. <br />
                        매체와 소셜 미디어를 활용한 PR 활동과 이벤트, 캠페인을 기획하며, 성과 분석을 통해 브랜드 인지도를 높이고 시장 내 위치를 강화합니다. <br />
                    </span>

                    <span className='Bold weightBold'>
                        웹 플랫폼 디자인
                    </span>
                    <span className='Bold'>
                        #UI/UX #Figma #Adobe
                    </span>
                    <span className='Light'>
                        포체인스의 기술을 활용해 사용자 중심의 웹 플랫폼을 설계하고, UI/UX 전략을 수립하여 복잡한 기능을 효과적으로 구성합니다.  <br />
                        이를 통해 사용자 경험을 최적화하고, 기능성과 시각적 효과를 고려하여 플랫폼 품질을 극대화합니다. <br />
                    </span>

                    <span className='Bold weightBold'>
                        콘텐츠 및 서비스 디자인
                    </span>
                    <span className='Bold'>
                        #Branding #UX Design #컬러
                    </span>
                    <span className='Light'>
                        포체인스의 콘텐츠와 서비스의 시각적 요소와 인터페이스를 체계적으로 설계하고, 툴을 활용해 디자인을 최적화합니다.  <br />
                        또한, 시각적 일관성과 효과적인 전달을 보장하며, 직관적인 사용자 경험을 제공합니다. <br />
                    </span>

                    <span className='Bold weightBold'>
                        성과 분석 및 평가
                    </span>
                    <span className='Bold'>
                        #KPI #Data Analytics #CVR #ROI 
                    </span>
                    <span className='Light'>
                        기술/마케팅 KPI를 설정해 사용자 확보, 브랜드 인식 제고, CVR 향상 등을 목표로 하며, 성과를 분석하고 전략을 개선합니다.  <br />
                        또한, 데이터 분석 툴을 활용해 데이터 기반의 인사이트를 제공하고 전략적 결정을 지원합니다.  <br />
                    </span>

                    <div className='DevelopImg'>
                        <img src={DevelopPlan} alt="DevelopPlan"/>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Develop; 