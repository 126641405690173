import React, { useEffect, useState } from "react";
import { animateScroll as scroll } from "react-scroll";
import {
    TeamCulture,
    FirstCulture,
    TeamSlide,
    DepartmentCulture,
    ScrollButton,
} from "../components/index";
import { useLocation } from "react-router-dom";


interface CultureProps {
    setCurrentSection: React.Dispatch<React.SetStateAction<string>>;
}

const CulturePage: React.FC<CultureProps> = ({ setCurrentSection }) => {
    const [currentPage, setCurrentPage] = useState(0);
    const [pageTitle, setPageTitle] = useState("");
    const lastPage = 2;

    useEffect(() => {
        const handleScroll = () => {
            const nextPage = Math.floor(window.scrollY / window.innerHeight);
            setCurrentPage(nextPage);
        };

        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, [currentPage]);

    const handleWheel = (e: WheelEvent) => {
        const isScrollingDown = e.deltaY > 0;

        if (isScrollingDown && currentPage < lastPage) {
            // 마우스 휠을 아래로 스크롤할 때 다음 페이지로 이동
            scrollToNextPage();
        } else if (!isScrollingDown && currentPage < lastPage) {
            // 마우스 휠을 위로 스크롤할 때 이전 페이지로 이동
            scrollToPrevPage();
        }
    };

    const scrollToNextPage = () => {
        const nextPage = currentPage + 1;
        const offset = nextPage * window.innerHeight + 1;
        scroll.scrollTo(offset, {
            duration: 700,
            smooth: "easeInOutQuart",
        });
    };

    const scrollToPrevPage = () => {
        const prevPage = currentPage;
        const offset = prevPage * window.innerHeight + 2;
        scroll.scrollTo(offset, {
            duration: 700,
            smooth: "easeInOutQuart",
        });
    };

    const location = useLocation();

    const scrollToElement = (elementId: string) => {
        const element = document.getElementById(elementId);
        if (element) {
            element.scrollIntoView({ behavior: "smooth" });
        }
    };

    useEffect(() => {
        const elementId = location.hash.substring(1);
        scrollToElement(elementId);
    }, [location]);

    useEffect(() => {
        // 이 컴포넌트가 마운트되면 휠 이벤트 리스닝
        window.addEventListener("wheel", handleWheel);

        // 컴포넌트 언마운트 시 이벤트 리스닝 정리
        return () => {
            window.removeEventListener("wheel", handleWheel);
        };
    }, [currentPage]);


    useEffect(() => {
        switch (currentPage) {
            case 0:
                setPageTitle("Culture");
                break;
            case 1:
                setPageTitle("Partner");
                break;
            case 2:
                setPageTitle("Team");
                break;
                case 3:
                    setPageTitle("Colleague");
                    break;
            default:
                break;
        }
    }, [currentPage]);

    const [activeButton, setActiveButton] = useState('culture1');

    useEffect(() => {
        setActiveButton(`culture${currentPage + 1}`);
    }, [currentPage]);

    const handleButtonClick = (elementId: string) => {
        scrollToElement(elementId);
        setActiveButton(elementId);
    };

    useEffect(() => {
        setCurrentSection("WhiteHeader");
        return () => {
        };
    }, [setCurrentSection]);

    return (
        <div className="CulturePage">
            <section id="culture1">
                <FirstCulture isfirstculturePage={currentPage === 0} />
            </section>
            <section id="culture2">
                <DepartmentCulture />
            </section>
            <section id="culture3">
                <TeamCulture />
            </section>
            <section id="culture4">
                <TeamSlide isCurtureSlidePage={currentPage === 2} />
            </section>
            <ScrollButton pageTitle={pageTitle} sectionTitle="culture" lastPage={4} activeButton={activeButton} onButtonClick={handleButtonClick} />
        </div>
    );
};

export default CulturePage;