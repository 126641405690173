import { Research, RnDProcedure } from "../../asset/img";


const DetailDevelop = () => {
return(
<div className='FourchainsClass DetailDevelop'>
    <div className='Fourchains'>
    <span className='ShortName'>
                    포체인스의 핵심부서
                </span>
                <span className='CenterName'>
                    R&D 센터 상세 연구 과정
                </span>
                <img src={RnDProcedure} />
                <span className='DevelopName SubTitle'>
                    세부 연구 분야 및 내용
                </span>
                <img src={Research} />
    </div>
</div>    
)
}

export default DetailDevelop;