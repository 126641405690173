import React from "react";

const Mobile2022 = () => {
    return (
        <div className="Mobile2023">
            <span className="MobileYears">
                2022
            </span>

            <div className="TextBox">
                <span className="BoldText">
                TIPS 프로그램 선정<br />
                벤처기업 인증<br />
                </span>
                <span className="Texts">
                제47차 대구 스타트업 리더스 포럼 선정<br />
                2022 고양 IR데이 성장상 수상 <br />
                2022 대전 과학기술 스타트업 액셀러레이팅 프로그램 선정 <br />
                2022 혁신한국인 & POWER KOREA 대상 <br />
                2022 동아일보 주관 혁신기업 대상 <br />
                2022 오피니언 리더 대상 <br />
                기술보증기금 투자 연계 보증 <br />
                에스이피홀딩스(주) 및 개인 투자 유치
                </span>
            </div>
        </div>
    )
}
export default Mobile2022;