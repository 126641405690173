import React, { useState, useEffect } from "react";

interface HomeServiceProps {
    isfirstServicePage: boolean;
}

const HomeService: React.FC<HomeServiceProps> = ({ isfirstServicePage }) => {

    const blurAnimation = isfirstServicePage
        ? 'MainSlogan 0.7s ease-in-out'
        : 'none';

    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        setLoaded(true);
    }, []);

    return (
        <div className={`HomeService ${loaded ? "loaded" : ""}`}>
            <div className="TextHome"
                style={{
                    animation: blurAnimation,
                    opacity: isfirstServicePage ? 1 : 0,
                }}
            >
                <span className="TabName">
                    Service
                </span>
                <div className="FCulHead">
                    새로운 길을 열어가는 우리의 모습입니다.
                </div>
                <div className="FCulBtm">
                    우리는 도전적인 상황에서도 새로운 길을 찾고,<br />
                    미래 보안 사업을 개척합니다.<br />
                    새로운 시도와 도전으로 포체인스는<br />
                    전례 없는 길을 개척하겠습니다.
                </div>
            </div>
        </div>
    )
}
export default HomeService;