import React, { useState, useEffect } from "react";
import { HeaderLogo } from "../../asset/img/index";
import { Link } from "react-router-dom";

const ManagerHeader = () => {
    const [userName, setUserName] = useState('');

    useEffect(() => {
        // localStorage에서 userName을 가져와 컴포넌트 상태에 설정
        const storedUserName = localStorage.getItem('userName');
        if (storedUserName) {
            setUserName(storedUserName);
        }
    }, []);
    return (
        <div className="ManagerHeader">
            <div className="Left">
                <Link to={"/"}>
                    <img src={HeaderLogo} alt="Header Logo" />
                </Link>
                <Link to={"/FourchainsManagerFirstPage"}>
                    <span className="HeaderName">포체인스 관리자</span>
                </Link>
            </div>
            <div className="Right">
                <span className="Manager">
                    <span className="Name">{userName}</span>&nbsp;관리자님
                </span>
                <button
                    className="Logout"
                >
                    로그아웃
                </button>
            </div>
        </div>
    );
};

export default ManagerHeader;
