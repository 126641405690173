import React from "react";

const Mobile2020 = () => {
    return (
        <div className="Mobile2023">
            <span className="MobileYears">
                2020
            </span>

            <div className="TextBox">
                <span className="Texts">
                    MCA 연구센터 창립
                </span>
            </div>
        </div>
    )
}
export default Mobile2020;