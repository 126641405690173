import React,{useState,useEffect} from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import {
  TeamSlideOne,
  TeamSlideThree,
  PrevSlide,
  NextSlide,
  TeamSlideFour,
  TeamSlideTwo,
} from "../../asset/img/index";

interface SlideProps {
  isCurtureSlidePage: boolean;
}

const SlideComponent: React.FC<SlideProps> = ({ isCurtureSlidePage }) => {
  const items = [
    "#의도를 파악하는 동료",
    "#전략적으로 고민하는 동료",
    "#명확하게 전달하는 동료",
    "#비판을 수용하는 동료"
  ];
  
  const [currentSlide, setCurrentSlide] = useState(0);

  const blurAnimation = isCurtureSlidePage
  ? 'blurUp 0.7s ease-in-out'
  : 'none';

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentSlide((prevSlide) => (prevSlide + 1) % items.length);
    }, 7000);

    return () => clearInterval(intervalId);
  }, [currentSlide]);

  return (
    <div className='TeamSlide'
  //   style={{
  //     animation: blurAnimation,
  //     opacity: isCurtureSlidePage ? 1 : 0,
  // }}
    >
      <div className='Start'>
        우리의 성공은 동료들의 열정과<br/>
        끊임없는 도전에서 출발합니다.
      </div>
      <div className='HashTagBox'>
        {items.map((item, index) => (
          <div className='HashTag' key={index}>
            {item}
          </div>
        ))}
      </div>
      <Carousel
        className='SlideCarousel'
        showThumbs={false}
        showStatus={false}
        selectedItem={currentSlide}
        showIndicators={false}
        renderArrowPrev={(onClickHandler, hasPrev, label) =>
          hasPrev && (
            <button onClick={onClickHandler} title={label} className="arrow-prev">
              <img src={PrevSlide} />
            </button>
          )
        }
        renderArrowNext={(onClickHandler, hasNext, label) =>
          hasNext && (
            <button onClick={onClickHandler} title={label} className="arrow-next">
              <img src={NextSlide} />
            </button>
          )
        }
      >
        <div className='Slide'>
          <img src={TeamSlideOne} alt="Slide a" />
          <div className='SlideTextBox'>
            <div className='Box'>
              의도를 파악하는 동료
            </div>
            <span>
              우리는 의도를 세밀히 이해하며 효율적인<br />
              협업과 의사소통을 이끌어내는 동료와 함께<br />
              더 나은 비전을 실현하고자 합니다.
            </span>
          </div>
        </div>
        <div className='Slide'>
          <img src={TeamSlideTwo} alt="Slide b" />
          <div className='SlideTextBox'>
            <div className='Box'>
              전략적으로 고민하는 동료
            </div>
            <span>
              문제를 전략적으로 고민하고<br />
              혁신적인 아이디어를 제시하는 동료와 함께<br />
              문화를 만들어갑니다.
            </span>
          </div>
        </div>
        <div className='Slide'>
          <img src={TeamSlideThree} alt="Slide c" />
          <div className='SlideTextBox'>
            <div className='Box'>
              명확하게 전달하는 동료
            </div>
            <span>
              우리는 명확하게 커뮤니케이션하고<br />
              정보를 효과적으로 전달하는 동료와 함께<br />
              새로운 길을 열어가겠습니다.
            </span>
          </div>
        </div>
        <div className='Slide'>
          <img src={TeamSlideFour} alt="Slide b" />
          <div className='SlideTextBox'>
            <div className='Box'>
              비판을 수용하는 동료
            </div>
            <span>
              우리는 비판을 수용하며 변화를 이끄는<br /> 
              동료와 함께 더 나은 결과를<br />
              창출하고자 합니다. 
            </span>
          </div>
        </div>
      </Carousel>
    </div>
  );
};

export default SlideComponent;
