import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Header, Content, Footer } from "./components/index";
import { ManagerHeader } from "./manager/component/index";
import "./style/index.scss";

const App = () => {
    const [currentSection, setCurrentSection] = useState('');
    const location = useLocation();
    const pathsWithManagerHeader = ['/FourchainsManagerFirstPage','/FourchainsManagerNewsRoomPage','/ManagerNewsEditDeletePage'];
    const pathsWithoutHeaderAndFooter = ['/FourchainsManagerDevPage', '/FourchainsManagerLoginPage'];

    const showHeaderAndFooter = !pathsWithoutHeaderAndFooter.includes(location.pathname);

    return (
        <div className='app'>
            {showHeaderAndFooter && (
                (pathsWithManagerHeader.includes(location.pathname) ? (
                    <ManagerHeader />
                ) : (
                    <Header currentSection={currentSection} setCurrentSection={setCurrentSection} />
                )) as React.ReactNode
            )}
            <Content setCurrentSection={setCurrentSection} />
            {showHeaderAndFooter &&
            location.pathname !== '/FourchainsManagerFirstPage' &&
            location.pathname !== '/FourchainsManagerFirstPage' &&
            location.pathname !== '/FourchainsManagerNewsRoomPage' &&
            location.pathname !== '/ManagerNewsEditDeletePage' && <Footer />}
        </div>
    );
}

export default App;
