import React from "react";
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Link } from "react-router-dom";
import {
    DepartRightArrow,
    DepartLeftArrow,
} from "../../asset/img/index";
import { Department } from "../index";

const DepartmentCulture = () => {
    const ArrowPrev = (onClickHandler: any, hasPrev: any, label: any) =>
        hasPrev && (
            <button
                type="button"
                onClick={onClickHandler}
                title={label}
                className="custom-arrow custom-arrow-prev"
            >
                <img src={DepartLeftArrow} alt="Prev" />
            </button>
        );

    const ArrowNext = (onClickHandler: any, hasNext: any, label: any) =>
        hasNext && (
            <button
                type="button"
                onClick={onClickHandler}
                title={label}
                className="custom-arrow custom-arrow-next"
            >
                <img src={DepartRightArrow} alt="Next" />
            </button>
        );

    const scrollToTop = () => {
        window.scrollTo(0, 0);
    };

    const handleMenuClick = (section: string) => {
        scrollToTop();
    };

    const items = [
        {
            id: 1,
            MainName: 'R&D 센터',
            SubName: '알고리즘 연구실 | 동형분석 연구실 | 블록체인 연구실',
            imgName: 'RnD',
        },
        {
            id: 2,
            MainName: '개발부',
            SubName: '개발 1팀 | 개발 2팀',
            imgName: 'Development',
        },
        {
            id: 3,
            MainName: '마케팅부',
            SubName: '기획팀 | 디자인팀',
            imgName: 'Marketing',
        },
        {
            id: 4,
            MainName: '관리부',
            SubName: '관리팀 | 지원팀 | 시설팀',
            imgName: 'Management',
        },
    ];
    const filteredItemsOne = items.filter(item => item.id === 1);
    const filteredItemsTwo = items.filter(item => item.id === 2);
    const filteredItemsThree = items.filter(item => item.id === 3);
    const filteredItemsFour = items.filter(item => item.id === 4);
    return (
        <div className="Department">
            <div className="MainTitle">포체인스 주요 부서</div>

            <div className="Title">
                우리 회사는 다양한 전문 분야를 아우르는<br/>
                주요 부서들로 구성되어 있습니다.
            </div>

            <div className="MainSlide">
                <Carousel
                    showStatus={false}
                    showThumbs={false}
                    renderArrowPrev={ArrowPrev}
                    renderArrowNext={ArrowNext}
                    className="SlideSBox"
                >
                    <div className="Box">
                        <Link to="/RandD" className="OneBox" onClick={() => { handleMenuClick("") }}>
                            <Department items={filteredItemsOne} />
                        </Link>
                        <Link to="/develop" className="OneBox" onClick={() => { handleMenuClick("") }}>
                            <Department items={filteredItemsTwo} />
                        </Link>
                    </div>
                    <div className="Box">
                        <Link to="/plan" className="OneBox" onClick={() => { handleMenuClick("") }}>
                            <Department items={filteredItemsThree} />
                        </Link>
                        <Link to="/manage" className="OneBox" onClick={() => { handleMenuClick("") }}>
                            <Department items={filteredItemsFour} />
                        </Link>
                    </div>
                </Carousel>
            </div>
        </div>
    );
};

export default DepartmentCulture;
