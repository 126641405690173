import React, { useState } from "react";
import { LoginLogo, CloseEye, OpenEye } from "../../asset/img";
import { ManagerLoginService } from "../../service/ManagerLoginService";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";

const Login = () => {
    const navigate = useNavigate();
    const [showPassword, setShowPassword] = useState(false);
    const [userId, setUserId] = useState("");
    const [password, setPassword] = useState("");

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const handleLogin = async () => {
        try {
            const response = await ManagerLoginService({ userId, password });

            if (response.data.success) {
                // 로그인 성공한 경우 userName을 저장
                const userName = response.data.userName;

                // 여기서는 localStorage를 사용하여 간단하게 저장하겠습니다.
                localStorage.setItem('userName', userName);

                // 다음 페이지로 이동
                navigate("/FourchainsManagerFirstPage");
            } else {
                console.log("Login failed");
            }
        } catch (error) {
            console.error("Login failed", error);
        }
    };

    const handleKeyDown = (event:any) => {
        if (event.key === "Enter") {
            handleLogin();
        }
    };

    return (
        <div className="ManagerLogin">
            <div className="Blur">
                <img src={LoginLogo} alt="Login Logo" />
                <div className="Manager">
                    포체인스 관리자
                </div>

                <div className="LoginForm">
                    <div className="IdBox">
                        <span>아이디</span>
                        <input
                            className="Id"
                            type="text"
                            value={userId}
                            onChange={(e) => setUserId(e.target.value)}
                            onKeyDown={handleKeyDown}
                        />
                    </div>
                    <div className="PwBox">
                        <span>비밀번호</span>
                        <div className="PasswordInput">
                            <input
                                className="Pw"
                                type={showPassword ? "text" : "password"}
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                onKeyDown={handleKeyDown}
                            />
                            <img
                                className="TogglePassword"
                                onClick={togglePasswordVisibility}
                                src={showPassword ? CloseEye : OpenEye}
                                alt={showPassword ? "숨기기" : "보이기"}
                            />
                        </div>
                    </div>
                </div>

                <button className="Manager_Login" onClick={handleLogin}>
                    로그인
                </button>
                <Link to={"/"}>
                <div className="MainBtn">Four-chains</div>
                </Link>
            </div>
        </div>
    );
};

export default Login;
