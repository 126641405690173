import React from "react";
import { AboutLine } from "../../asset/img/index";

interface AboutProps {
    isAboutPage: boolean;
}

const About: React.FC<AboutProps> = ({ isAboutPage }) => {

    const blurAnimation = isAboutPage
    ? 'blurUp 0.5s ease-in-out'
    : 'none';

    return (
        <div className="About" id="page2">
            <div
                className="Blur"
                style={{
                    animation: blurAnimation, 
                    opacity: isAboutPage ? 1 : 0,
                }}
            >
                <div className="BlueHead">
                    저희는 타원 곡선 연구를 기반으로<br /> 동형암호 스킴과 양자 내성 암호 스킴 개발을 완료하여<br />
                    미래의 AI 해킹 공격과 양자 컴퓨팅 공격에 대한 대응을 준비 중입니다.<br />
                    더 나아가 개인 정보 식별 이슈에 대한 자유로움을 위해 개인 식별에 활용되는<br />
                    블록체인 기술 연구를 진행 중입니다.<br />
                    <div className="BlueBtm">
                    미래 데이터 보안 사업에 대한 연구와 개발을 주도하는 기업<br />
                    포체인스와 함께 안전한 새 시대를 맞이하세요.
                    </div>
                </div>
            </div>
            <div
                className="TabletBlur"
            >
            <div className="BlueHead">
                    저희는 타원 곡선 연구를 기반으로<br /> 동형암호 스킴과 양자 내성 암호 스킴 개발을 완료하여<br />
                    미래의 AI 해킹 공격과 양자 컴퓨팅 공격에 대한 대응을 준비 중입니다.<br />
                    더 나아가 개인 정보 식별 이슈에 대한 자유로움을 위해 개인 식별에 활용되는<br />
                    블록체인 기술 연구를 진행 중입니다.<br />
                    <div className="BlueBtm">
                    미래 데이터 보안 사업에 대한 연구와 개발을 주도하는 기업<br />
                    포체인스와 함께 안전한 새 시대를 맞이하세요.
                    </div>
                </div>
            </div>
        </div>
    )
}

export default About;
