import React, { useState } from "react";
import {
    ECCELISLogo,
    CryptoBlendXLogo,
    CryptifyMELogo,
    CryptoBlockchainLogo
} from "../../asset/img/index";
import { Link } from "react-router-dom";
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";

const Solution = () => {
    const [hovered, setHovered] = useState(false);

    const handleMouseEnter = () => {
        setHovered(true);
    };

    const handleMouseLeave = () => {
        setHovered(false);
    };
    const ServiceItems = [
        {
            id: 1,
            Name: 'ECCELIS',
            explanation:
                `
                <div>
                자사가 보유하고 있는 암호 기술을 오픈소스 형태로 배포하기 위하여 라이브러리 ECCELIS를<br/>
                개발하였고, 현 라이브러리는 양자 내성 암호(PQC)인 Isogeny-based Cryptosystem과<br/>
                동형암호(HE)에 관한 Elliptic Curve Homomorphic Cyrptosystem<br/>
                그리고 전자서명 알고리즘 등을 포함하고 있습니다. 
                </div>
                `,
            img: ECCELISLogo,
        },
        {
            id: 2,
            Name: 'CryptoBlendX',
            explanation:
                `
                <div>
                CryptoBlendX는 포체인스(주)의 핵심기술인 타원곡선 기반<br/>
                동형암호(Elliptic Curve Homomorphic Cryptosystem, ECHC)를 활용한<br/>
                암호화 기반 분석(동형분석) 기술 솔루션입니다.<br/>
                CryptoBlendX는 암호화 상태에서 복호화 과정 없이 통계 분석 및 머신러닝 기반 분석을 빠르고<br/>
                정확하게 진행하는 것이 특징입니다.
                </div>
                `,
            img: CryptoBlendXLogo,
        },
        {
            id: 3,
            Name: 'CryptifyME',
            explanation:
                `
                <div>
                동형암호의 핵심 활용 방안인 암호화를 통한 개인 정보 데이터를 가명화하고<br/>
                가명화 상태에서 데이터를 처리하는 환경 구축을 솔루션화 하여<br/>
                개인정보 식별 시스템 CryptifyME를 개발 완료하였습니다.<br/>
개인정보를 활용하는 모든 기업이 사용할 수 있도록 직관적인 인터페이스와 기술 서비스를 제공합니다.
                </div>
                `,
            img: CryptifyMELogo,
        },
        {
            id: 4,
            Name: 'Crypto Blockchain',
            explanation:
                `
                <div>
                포체인스(주)의 핵심기술인 타원곡선 기반 동형 암호<br/>
                (Elliptic Curve Homomorphic Cryptosystem, ECHC)의 기술적 특징인 경량성 및 높은<br/>
                보안력을 활용하여 새로운 블록체인을 개발 완료하였습니다. ECHC를 통한 트랜잭션 데이터를<br/>
                암호화하여 블록체인에 저장하고 이를 활용할 수 있는 네트워크 환경을 제공합니다.<br/>
                자사의 서비스 STORNGBOX와 연계하여 개인정보 지갑(유저용)과<br/>
                스마트 계약 플랫폼(고객사용) 서비스를 제공합니다. 
                </div>
                `,
            img: CryptoBlockchainLogo,
        },
    ];

    return (
        <div>
            <div className="Sevicess">
                <div className="TextBox">
                    <span className="Explan">사용자들의 편리성을 생각한 솔루션</span>
                    <span className="Name">솔루션</span>
                </div>
                <div className="FlexBox">
                    {ServiceItems.map((item, index) => (
                        <div key={index} className="ServiceExplanBox">
                            <div
                                className="image-container"
                                onMouseEnter={handleMouseEnter}
                                onMouseLeave={handleMouseLeave}
                            >
                                <img className="MainImg" src={item.img} alt={item.Name} />
                            </div>
                            <div className="ItemTextBox">
                                <span className="ItemName">{item.Name}</span>
                                <span className="ItemExplan" dangerouslySetInnerHTML={{ __html: item.explanation }}></span>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <div className="MobileServicess">
                <span className="TextName">
                    솔루션
                </span>
                <Carousel
                    showStatus={false}
                    showThumbs={false}
                    showArrows={true}
                    showIndicators={false}
                    className="SlideSBox"
                    autoPlay
                    interval={5000}
                >
                    {ServiceItems.map(item => (
                        <div key={item.id} className="KeyFrame">
                            <img src={item.img} className="ImgFrame" alt={item.Name} />
                            <span className="ItemName">{item.Name}</span>
                            <span className="ItemExplan" dangerouslySetInnerHTML={{ __html: item.explanation }}></span>
                        </div>
                    ))}
                </Carousel>
            </div>
        </div>
    );
};

export default Solution;
