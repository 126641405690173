import React from "react";
import {
    Solution,
    SolutionProcedure
} from "../../components/index";

const Service = () => {
    return (
        <div>
            <Solution />
            <SolutionProcedure />
        </div>
    )
}
export default Service;