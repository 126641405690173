import { Link } from "react-router-dom";
import { FooterLogo, ShortBreakLine } from "../asset/img";
import { FamilySiteUpArrow, FamilySiteDownArrow, Instagram, NaverBlog } from "../asset/img";
import { useEffect, useState } from "react";

const SNS_INFOS = [
  {
    img: Instagram,
    url: "https://instagram.com/fourchains_insta?igshid=MmVlMjlkMTBhMg==",
  },
  { img: NaverBlog, url: "https://blog.naver.com/four-chains" }
];

const COMPANY_MAIN_INFOS = [
  {
    title: "대표",
    content: "이정훈",
  },
  {
    title: "본사",
    content: "대구광역시 중구 국채보상로 558-1, 2층 90호",
  },
  {
    title: "R&D 연구센터",
    content: "경기도 고양시 일산서구 킨텍스로 217-59 제2킨텍스 오피스동 703호",
  },
];

const COMPANY_INFOS = [
  {
    title: "대표전화",
    content: `031-995-6409`,
  },
  {
    title: "이메일",
    content: `info@four-chains.com`,
  },
  {
    title: "사업자 등록번호",
    content: "457-81-02412",
  },
];

const SITEMAP_LIST = [
  { title: "About", page: ["Home", "Vision", "History", "Management"], sectionTitle: "page" },
  { title: "Service", page: ["Solution", "Product"], sectionTitle: "service" },
  { title: "Team Culture", page: ["Culture", "Partner", "Team", "Colleague"], sectionTitle: "culture" },
  {
    title: "Community",
    page: ["NewsRoom"],
    sectionTitle: "community"
  },
];

const SITEMAP_LIST_LAST = [
  { page: "PartnerCompany", sectionTitle: "page", pageNum: 5 }
]

const FAMILYSITE_LIST = [
  {
    name: "FCTS",
    url: "https://fc-ts.com",
  },
  { name: "PROBE", url: "https://probe.kr/about" },
  { name: "MODULUS", url: "https://fcts-mod.com" },
  { name: "Crypto Blockchain", url: "http://crypto-blockchain.org" },
  { name: "BI-Mining", url: "https://bi-mining.org" },
];

const Footer = () => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isLogin, setIsLogin] = useState(false);

  const handleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  useEffect(() => {
    const loginUser = localStorage.getItem("userName");
    if (loginUser) setIsLogin(true)
    else setIsLogin(false)
  }, [])

  const scrollTop = () => {
    window.scrollTo(0, 0);
  };

  const handleMenuClick = (section: string) => {
    scrollTop();
  };

  return (
    <div className="Footer">
      <section className="MainSection">
        <div className="LeftSection">
          <div className="CompanyName">(주)포체인스</div>
          <a href="#">
            <img src={FooterLogo} alt="Fourchains footer logo" />
          </a>
          <div className="MiddleWrapper">
            <div className="CompanyInfoWrapper">
              {COMPANY_MAIN_INFOS.map((info) => {
                return (
                  <div key={info.title}>
                    {info.title} : {info.content}
                  </div>
                );
              })}
              {COMPANY_INFOS.map((info) => {
                return (
                  <span key={info.title} className="CompanyInfo">
                    <span>
                      {info.title} : {info.content}
                    </span>
                    {info.title !== "사업자 등록번호" && <span> | </span>}
                  </span>
                );
              })}
              {COMPANY_INFOS.map((info) => (
                <div className="CompanyInfoMobile" key={info.title}>
                  {info.title} : {info.content}
                </div>
              ))}
            </div>
          </div>
          <div className="CopyrightInfo">
            <div className="Crop">
              <span className="Underline">이용약관</span>
              <img
                src={ShortBreakLine}
                className="ShortBreakLine"
                alt="short break line"
              />
              <span className="Underline">개인정보 처리방침</span>
              <img
                src={ShortBreakLine}
                className="ShortBreakLine"
                alt="short break line"
              />
              <Link to={"/Contact"} onClick={() => { handleMenuClick("") }}>
                <span className="Underline">IR, PR 자료 문의</span>
              </Link>
            </div>
            <div className="Copyright">
              Copyright 2021 (주)포체인스 All rights reserved.
            </div>
          </div>
          <div className="SnsWrapper">
            {SNS_INFOS.map((sns) => (
              <a target="_blank" key={sns.img} href={sns.url}>
                <img src={sns.img} />
              </a>
            ))}
          </div>
        </div>
        <div className="SiteInfo">
          <div className="TopSiteInfo">
            <div className="SiteMapFamilySiteWrapper">
              <div className="SiteMapTitle">사이트맵</div>
            </div>

            <div className="SiteMapListWrapper">
              {SITEMAP_LIST.map((menu) => (
                <div key={menu.sectionTitle}>
                  <Link to={menu.sectionTitle === "page" ? `/#${menu.sectionTitle}1` : `/${menu.sectionTitle}#${menu.sectionTitle}1`}>
                    <span>{menu.title}</span>
                  </Link>
                  {menu.page.map((page, idx) => (
                    <Link key={idx} to={menu.sectionTitle === "page" ? `/#${menu.sectionTitle}${idx + 1}` : `/${menu.sectionTitle}#${menu.sectionTitle}${idx + 1}`}>
                      <div className="SectionPageName">{page}</div>
                    </Link>
                  ))}
                </div>
              ))}
              <div className="LastSiteMapListWrapper">
                {SITEMAP_LIST_LAST.map((menu) => (
                  <Link key={menu.page} to={menu.sectionTitle === "page" ? `/#${menu.sectionTitle}${menu.pageNum}` : `/${menu.sectionTitle}#${menu.sectionTitle}${menu.pageNum}`}>
                    <div>{menu.page}</div>
                  </Link>
                ))}
              </div>
            </div>
          </div>
          <div className="BottomSiteInfo">
            <div className="FamilySiteBtn">
              <span onClick={handleDropdown}>
                패밀리 사이트
                <img src={isDropdownOpen ? FamilySiteDownArrow : FamilySiteUpArrow} alt="dropdown arrow button" />
              </span>
              {isDropdownOpen && (
                <div className="Dropdown">
                  <ul>
                    {FAMILYSITE_LIST.map((site) => {
                      return (
                        <a href={site.url} key={site.name} target="_blank">
                          <li className="FamilySiteName">{site.name}</li>
                        </a>
                      );
                    })}
                  </ul>
                </div>
              )}
            </div>
            <div className="FamilySiteBtnDownWrapper">
              <div className="FamilySiteBtnDown">
                <span onClick={handleDropdown}>
                  패밀리 사이트
                  <img src={isDropdownOpen ? FamilySiteUpArrow : FamilySiteDownArrow} alt="dropdown arrow button" />
                </span>
                {isDropdownOpen && (
                  <div className="Dropdown">
                    <ul>
                      {FAMILYSITE_LIST.map((site) => {
                        return (
                          <a href={site.url} key={site.name} target="_blank">
                            <li className="FamilySiteName">{site.name}</li>
                          </a>
                        );
                      })}
                    </ul>
                  </div>
                )}
              </div>
            </div>
            <div className="ManagerWrapper">
              <Link to={isLogin ? "/FourchainsManagerFirstPage" : "/FourchainsManagerLoginPage"}>
                <span className="Underline">관리자 사이트</span>
              </Link>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Footer;
