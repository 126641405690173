import React, { useEffect } from 'react';
import { ManageBack, DevelopManage } from "../../asset/img/index";


interface DevelopProps {
    setCurrentSection: React.Dispatch<React.SetStateAction<string>>;
}

const Manage: React.FC<DevelopProps> = ({ setCurrentSection }) => {

    useEffect(() => {
        setCurrentSection("history");
        return () => {
        };
    }, [setCurrentSection]);

    return (
        <div className='FourchainsClass'>
            <div className='Fourchains'> 
                <span className='ShortName'>
                    포체인스의 핵심부서
                </span>
                <span className='CenterName'>
                    관리부
                </span>
                <img src={ManageBack} alt="ManageBack"/>
                <span className='DevelopName'>
                    관리 역량
                </span>

                
                <div className='Develop'>
                    <span className='Bold weightBold'>
                      인사관리
                    </span>
                    <span className='Bold'>
                      #Office활용 #문서 및 보고서 작성 #채용프로세스 #교육 및 성과 관리
                    </span>
                    <span className='Light'>
                      기업의 훌륭한 인재 성장을 위한 직원 채용, 교육, 성과 관리 등 인사 업무를 종합적으로 진행합니다. 이를 통해 우수 인재를 확보하고, 직원의 역량을 강화하며, 성과를 체계적으로 평가해 조직의 목표 달성을 지원합니다.
                    </span>

                    <span className='Bold weightBold'>
                      재무 관리
                    </span>
                    <span className='Bold'>
                      #재무/회계 분석 #문서 및 보고서 작성 
                    </span>
                    <span className='Light'>
                      예산 편성, 경비 관리, 재무 보고를 포함하여 회사의 재무 건전성을 유지합니다. 예산을 체계적으로 편성하고, 경비를 효율적으로 관리하며, 재무 보고를 통해 투명한 재무 상태를 확보합니다.
                    </span>

                    <span className='Bold weightBold'>
                      사무 관리
                    </span>
                    <span className='Bold'>
                      #재고 관리 #Forecasting
                    </span>
                    <span className='Light'>
                      사무용품 관리, 문서 관리 시스템 운영, 회의 및 일정 관리를 포함하여 사무 환경을 효율적으로 유지합니다. 이를 통해 업무의 원활한 진행과 조직의 효율성을 극대화합니다.
                    </span>

                    <span className='Bold weightBold'>
                      시설 관리
                    </span>
                    <span className='Bold'>
                      #시설 및 공간 관리 #커뮤니케이션
                    </span>
                    <span className='Light'>
                      사무실 유지보수, 청소, 안전 관리를 포함하여 직원들이 안전하고 쾌적한 환경에서 근무할 수 있도록 지원합니다. 최적의 업무 환경을 유지하고 조직의 효율성을 증진합니다.
                    </span>

                    <span className='Bold weightBold'>
                      서비스 지원 업무
                    </span>
                    <span className='Bold'>
                      #Office활용 #자료 준비 #커뮤니케이션
                    </span>
                    <span className='Light'>
                      서비스화 및 회사 관리에 필요한 서류와 자료를 준비하여 업무 관리를 원활하게 지원합니다. 이를 통해 효율적인 업무 처리를 지원하고 조직의 운영을 강화합니다.
                    </span>

                    <div className='DevelopImg'>
                        <img src={DevelopManage} alt="DevelopManage"/>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Manage; 