import React from "react";

interface DepartmentProps {
    items: Array<{
        id: number;
        MainName: string;
        SubName: string;
        imgName: string;
    }>;
}

const Department: React.FC<DepartmentProps> = ({ items }) => {
    return (
        <div className="MainDepartment">
            {items.map((item, index) => (
                <div className={`DeBox ${item.imgName}`} key={index}>
                    <div className="Top" />
                    <div className="Btm">
                        <span className="MainName">{item.MainName}</span><br/>
                        <span className="SubName">{item.SubName}</span>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default Department;
