import React from "react";

const Ver2024 = () => {
    return (
        <div className="History2019">
            <div className="Years2019">
                2024
            </div>
            <div className="BoldHis">
                TIPA ValueUP 프로그램 15기 선정<br />
                KBIPA 공급기업 선정<br />
                2024 대구광역시 중소기업 기술정보 지원사업 선정<br />
                2024 부산 블록체인 기업 투자유치 및 사업화 지원사업 선정<br />
                2024 블록체인 수요-공급자 협의체(ABLE) 블록체인 공급기업 선정<br />
                2024 중소기업 빅데이터 분석 지원사업 선정<br />
                2024 고양형 민간투자연계 기술창업지원 (TIPS) 사업 선정<br />
                BOUNCE 초격자 액셀러레이팅 프로그램 선정
            </div>
            <div className="His2019">
                
            </div>
        </div>
    )
}

export default Ver2024;