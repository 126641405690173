import React, { useState } from "react";
import {
    DetailServiceProbe,
    DetailServiceBI,
    DetailServiceModulus,
    DetailServiceStrong
} from "../../asset/img/index";
import { Link } from "react-router-dom";
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";

const Servicess = () => {
    const [hovered, setHovered] = useState(false);

    const handleMouseEnter = () => {
        setHovered(true);
    };

    const handleMouseLeave = () => {
        setHovered(false);
    };
    const ServiceItems = [
        {
            id: 1,
            Name: 'StrongBox',
            explanation:
                `
                <div>
                STRONGBOX는 자사의 블록체인 기술인 Crypto Blockchain을 활용하여 만든 서비스로,<br/>
                고객사에 스마트 계약 플랫폼을 제공하여 일반 사용자들이 개인정보 지갑(APP)을 통해<br/>
                개인정보를 기입할 수 있도록 서비스를 개발하였습니다. 또한, 데이터 유통과정에서는 자사의<br/>
                동형암호 기술을 적용하여 가명화상태에서 처리할 수 있도록 안전한 보안 환경을 제공하고 있습니다. 
                </div>
                `,
            img: DetailServiceStrong,
            link: "http://crypto-blockchain.org/"
        },
        {
            id: 2,
            Name: 'Modulus',
            explanation:
                `
                <div>
                MODULUS는 데이터 분석 프레임워크로 Python 언어 기반의 데이터 분석 코딩을 진행하고<br/>
                패시브 하게 데이터 가시화를 진행하여 코딩이 어려운 도메인 전문가들이 손쉽게 분석할 수 있도록<br/>
                환경을 제공합니다. 또한 자동으로 분석 네트워크를 구성하여 팀 유저들이 협업할 수 있도록<br/>
                기능을 개발하였고, 분석 네트워크의 보안 환경은 자사의 암호 기술과 보안 네트워크를 통하여 보호합니다.
                </div>
                `,
            img: DetailServiceModulus,
            link: "https://fcts-mod.com/"
        },
        {
            id: 3,
            Name: 'BI-Mining',
            explanation:
                `
                <div>
                BI-Mining은 BI 업무를 쉽고 효율적 진행할 수 있도록 개발한 업무 툴(Tool)입니다.<br/>
                데이터 핸들링, 자동 PPT 작성, 보고서 작성 기능 등을 기능으로 제공하고 있고,<br/>
                블록체인 기반 설문조사 기능을 핵심 기능으로 보유하고 있습니다.
                </div>
                `,
            img: DetailServiceBI,
            link: "https://bi-mining.org/"
        },
        {
            id: 4,
            Name: 'Probe',
            explanation:
                `
                <div>
                멀웨어 데이터 탐지 백신 소프트웨어 Probe는 자사가 새롭게 연구·개발한 p-레벨 웨이트<br/>
                거리 함수를 통하여 새로운 멀웨어 데이터를 탐지할 수 있도록 원천기술 개발하였습니다.<br/>
                지속적인 연구를 통하여 다양한 멀웨어 데이터를 더욱 정확하게 탐지할 수 있도록 노력하고 있습니다.
                </div>
                `,
            img: DetailServiceProbe,
            link: "https://probe.kr/about"
        },
    ];

    return (
        <div>
            <div className="Sevicess">
                <div className="TextBox">
                    <span className="Explan">사용자들의 편리성을 생각한 서비스</span>
                    <span className="Name">서비스</span>
                </div>
                <div className="FlexBox">
                    {ServiceItems.map((item, index) => (
                        <div key={index} className="ServiceExplanBox">
                            <Link to={item.link}
                                target="_blank"
                                className="image-container"
                                onMouseEnter={handleMouseEnter}
                                onMouseLeave={handleMouseLeave}
                            >
                                <img className="MainImg" src={item.img} alt={item.Name} />
                                {hovered && (
                                    <div className="overlay">
                                        <span className="overlay-text">바로가기</span>
                                    </div>
                                )}
                            </Link>
                            <div className="ItemTextBox">
                                <span className="ItemName">{item.Name}</span>
                                <span className="ItemExplan" dangerouslySetInnerHTML={{ __html: item.explanation }}></span>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <div className="MobileServicess">
                <span className="TextName">
                    서비스
                </span>
                <Carousel
                    showStatus={false}
                    showThumbs={false}
                    showArrows={true}
                    showIndicators={false}
                    className="SlideSBox"
                    autoPlay
                    interval={5000}
                >
                    {ServiceItems.map(item => (
                        <div key={item.id} className="KeyFrame">
                            <img src={item.img} className="ImgFrame" alt={item.Name} />
                            <span className="ItemName">{item.Name}</span>
                            <span className="ItemExplan" dangerouslySetInnerHTML={{ __html: item.explanation }}></span>
                        </div>
                    ))}
                </Carousel>
            </div>
        </div>
    );
};

export default Servicess;
