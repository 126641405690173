import React from "react";

const Mobile2021 = () => {
    return (
        <div className="Mobile2023">
            <span className="MobileYears">
                2021
            </span>

            <div className="TextBox">
                <span className="BoldText">
                포체인스(주) 법인 설립<br/>
                </span>
                <span className="Texts">
                AI 솔루션 부서 개설<br />
                ㈜프로카젠과 업무협약(MOU) 체결 <br />
                ㈜액트너랩으로부터 투자 유치 <br />
                기업부설연구소 설립
                </span>
            </div>
        </div>
    )
}
export default Mobile2021;