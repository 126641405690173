import React, { useEffect } from 'react';
import {
    Developer,
    DevelopDev
} from "../../asset/img/index";

interface DevelopProps {
    setCurrentSection: React.Dispatch<React.SetStateAction<string>>;
}

const Develop: React.FC<DevelopProps> = ({ setCurrentSection }) => {

    useEffect(() => {
        setCurrentSection("history");
        return () => {
        };
    }, [setCurrentSection]);

    return (
        <div className='FourchainsClass'>
            <div className='Fourchains'>
                <span className='ShortName'>
                    포체인스의 핵심부서
                </span>
                <span className='CenterName'>
                    개발부
                </span>
                <img src={Developer} alt="Developer"/>
                <span className='DevelopName'>
                    개발 역량
                </span>

                <div className='Develop'>
                    <span className='Bold weightBold'>
                        프론트엔드 / 백엔드 개발
                    </span>
                    <span className='Bold'>
                        #React #Typescript #redux #Node.js # Express.js #Docker .. 
                    </span>
                    <span className='Light'>
                        웹 플랫폼의 사용자 인터페이스 및 경험을 최적화하며, 서버와 데이터베이스, 애플리케이션 로직의 설계와 관리를 수행합니다.
                        다양한 기술 스택을 활용하여 시스템의 기능성과 성능을 극대화하며, 효율적인 상태 관리와 데이터 처리를 보장합니다.
                    </span>
                   
                    <span className='Bold weightBold'>
                        웹 플랫폼 서비스 개발
                    </span>
                    <span className='Bold'>
                        #UI/UX #My-SQL #Figma #Github #Notion
                    </span>
                    <span className='Light'>
                        포체인스에서 연구된 기술을 안정적이고 효율적인 웹 플랫폼 솔루션으로 제공하기 위해,
                        서버 측에서는 데이터 처리와 데이터베이스 상호작용을, 클라이언트 측에서는 사용자 인터페이스 구축과 원활한 통신을 담당합니다.
                    </span>

                    <span className='Bold weightBold'>
                        통합 웹 애플리케이션 솔루션 제공
                    </span>
                    <span className='Bold'>
                        #PlatformStability #ServerClientlntegration 
                    </span>
                    <span className='Light'>
                        타 부서와의 협력을 통해 요구사항을 명확히 하고 프로젝트 진행 상황을 조율하여 최적의 솔루션을 구현합니다.
                        부서 간의 원활한 소통을 통해 정보 공유를 촉진하고, 기술적 도전과제를 효과적으로 해결하여 회사의 비즈니스 목표를 충족하는 최상의 결과를 제공합니다.
                    </span>

                    <span className='Bold weightBold'>
                        AI 개발
                    </span>
                    <span className='Bold'>
                        #MachineLearning #DeepLearning
                    </span>
                    <span className='Light'>
                        머신러닝/딥러닝 기술로 AI 모델을 설계, 최적화합니다. 대량의 데이터를 수집하고 분석해 모델을 개발하며, 이를 시스템에 통합하고 배포합니다.
                        이후, 성능 모니터링과 업데이트를 통해 AI 솔루션의 신뢰성과 효과성을 유지합니다.
                    </span>

                    <span className='Bold weightBold'>
                        보안 및 성능 최적화 지원
                    </span>
                    <span className='Bold'>
                        #암호화 #취약점분석 #로그분석 #성능모니터링  
                    </span>
                    <span className='Light'>
                        웹 서비스의 보안을 위해 취약점 분석 및 수정, 암호화와 인증, 보안 프로토콜 준수를 수행하며, 코드와 쿼리 최적화로 속도와 효율성을 개선합니다.
                        또한, 성능을 지속적으로 모니터링하고 개선하는 업무를 진행합니다.
                    </span>

                    <span className='Bold weightBold'>
                        API 관련 서비스 개발
                    </span>
                    <span className='Bold'>
                        #API 설계 #보안 #유지보수 #프로그래밍 언어 #프레임워크
                    </span>
                    <span className='Light'>
                        포체인스 기술기반 솔루션을 API 형태로 서비스화하기 위해, 프로그래밍 언어와 프레임워크를 활용하여 API를 설계하고 개발합니다.
                        이를 통해, 시스템 간 상호작용과 안정성을 보장하고 고품질 API를 제공합니다.
                    </span>

                    <div className='DevelopImg'>
                    <img src={DevelopDev} alt="DevelopDev"/>
                </div>
                </div>
            </div>
        </div>
    )
}
export default Develop; 