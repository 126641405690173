import React from "react";
import { ServiceProcedureImg } from "../../asset/img/index";

const ServiceProcedure = () => {
    return (
        <div className="Procedure">
            <span className="Explanation">
                사용자들의 편리성을 생각한 서비스
            </span>
            <span className="BigText">
                서비스 개발을 위한 협력 과정
            </span>
            <img className="SolutionImg" src={ServiceProcedureImg} />
        </div>
    )
}
export default ServiceProcedure;