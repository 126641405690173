import { useEffect, useState } from "react";

const MAIN_SLOGAN_LIST = [
  {
    title: "동형암호에 양자 내성을 더하다.",
    secondTitle: "FOUR-CHAINS Schema",
    content:
      "양자 컴퓨팅 공격으로부터 보호하는 양자 내성 암호 스킴을 소개합니다",
    slideNum: 0,
  },
  {
    title: "데이터 보안 이슈 대응",
    secondTitle: "암호의 새 시대를 열어갑니다.",
    content: "AI 공격으로부터 보호하는 동형암호 스킴을 소개합니다",
    slideNum: 1,
  },
  {
    title: "신뢰받는 개인 식별 기술",
    secondTitle: "Crypto Blockchain이 실현합니다.",
    content: "개인정보 및 범용 데이터를 안전하게 보호합니다",
    slideNum: 2,
  },
];

const MainSlogan = () => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [fade, setFade] = useState(false);
  const onClickSlideBtn = (slideNum: number) => {
    setCurrentSlide(slideNum);
  };

  const blurAnimation = fade ? "MainSlogan 3s ease-in-out" : "none";

  useEffect(() => {
    setFade(false);
    const fadeTimer = setTimeout(() => setFade(true), 100);
    const slideTimer = setInterval(() => {
      if (currentSlide === 2) setCurrentSlide(0);
      else setCurrentSlide((currentSlide) => currentSlide + 1);
    }, 7000);
    return () => {
      clearTimeout(fadeTimer);
      clearInterval(slideTimer);
    };
  }, [currentSlide]);

  return (
    <div className="MainSlogan">
      <div className="MainContainer">
        {MAIN_SLOGAN_LIST.map((slogan) => {
          return (
            slogan.slideNum === currentSlide && (
              <div
                key={slogan.slideNum}
                className="MainSloganTextWrapper"
                style={{
                  animation: blurAnimation,
                  opacity: fade ? 1 : 0,
                }}
              >
                <div className="MainSloganTitle">{slogan.title}</div>
                <div className="MainSloganTitleSecond">
                  {slogan.secondTitle}
                </div>
                <div className="MainSloganContent">{slogan.content}</div>
              </div>
            )
          );
        })}
        <div className="SlideBtnWrapper">
          {MAIN_SLOGAN_LIST.map((slogan) => (
            <div
              key={slogan.slideNum}
              onClick={() => onClickSlideBtn(slogan.slideNum)}
              className={
                slogan.slideNum === currentSlide
                  ? "ActiveSlideBtn SlideBtn"
                  : "SlideBtn"
              }
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default MainSlogan;
