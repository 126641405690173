import React from "react";
import { MainTop, SustainImg } from "../../asset/img/index";
import { animateScroll as scroll } from "react-scroll";

interface SustainProps {
    isSustainPage: boolean;
}

const Sustainable: React.FC<SustainProps> = ({ isSustainPage }) => {

    const blurAnimation = isSustainPage
        ? 'blur 0.5s ease-in-out'
        : 'none';

    return (
        <div className="Sustainable">
            <div className="SusImgBox">
                <img src={SustainImg} />
            </div>
            <div className="SusText">
                <div className="MainText">
                    포체인스의 비전을 펼치겠습니다.
                </div>
                <div className="SubText">
                    포체인스는 혁신적인 안전성 솔루션을 개발하여 새로운 디지털 시대를 주도하고자 하는 미션을 갖고 있습니다.<br />
                    우리의 목표는 암호 기술을 중심으로 한 보안 혁신을 선도함으로써 암호 기술의 글로벌 리더로 발돋움하는 것입니다.<br />
                    이를 위해 국내에서 드물게 연구되고 있는 동형암호, 양자 내성 암호, 그리고 블록체인과의 융합 등<br />
                    다양한 기술 연구를 지속해서 추진하고 있습니다.<br /><br />
                    우리는 연구와 개발을 통해 암호 솔루션을 혁신하고, 이를 토대로 우리의 구성원들이 회사의 핵심 가치라고 믿습니다.<br />
                    회사의 발전을 위해 구성원의 지속적인 성장과 현장 경험을 중시하며,<br />
                    리더는 다양한 분야에서 업무를 수행하고 이를 깊이 이해하는 것을 중요시합니다.<br /><br />
                    우리는 기술 혁신의 진정한 의미를 깨달아, 세상에 기여할 수 있는 기술적 솔루션을 수학적 기반 위에서 명확하게 제시하고 연구합니다. <br />
                    양자 내성 암호 기술 연구를 통해 미래의 보안에 대한 새로운 가능성을 탐구하고 있으며,<br />
                    미래에 상용화될 양자 컴퓨팅에 대한 공격에 대비하기 위해 핵심 기술의 지속적인 연구 및 개발을 펼치고 있습니다. <br /><br />
                    우리의 비전은 미래의 디지털 환경에서의 안전과 혁신을 선도하는 것입니다.
                </div >
                <div className="Orner">
                    2021.09.23 대표 이 정 훈
                </div>
            </div>
        </div>
    )
}

export default Sustainable;