import { useEffect, useState } from "react";
import { NewsRoomSearch } from "../../asset/img";
import { IPost } from "./NewsRoom";

interface ISearchProps{
  active : string, setPageArrNum : React.Dispatch<React.SetStateAction<number>>, setCurrentPage : React.Dispatch<React.SetStateAction<number>>, setFilteredPosts: React.Dispatch<React.SetStateAction<IPost[]>>, tabPosts : IPost[], posts : IPost[],
  searchTerm:string, setSearchTerm: React.Dispatch<React.SetStateAction<string>>
}

const Search = ({active, setPageArrNum, setCurrentPage, tabPosts, posts, setFilteredPosts, searchTerm, setSearchTerm}: ISearchProps) => {
  
  const handleSearch = () => {
    if (searchTerm.trim() === "") {
      setFilteredPosts([]);
      setPageArrNum(0);
      setCurrentPage(1);
    } else {
      const filtered = (active !== "전체" ? tabPosts : posts)?.filter(
        (post: IPost) => {
          const isMatchingTitle = post.title
            .toLowerCase()
            .includes(searchTerm.toLowerCase());
          return isMatchingTitle;
        }
      );
      setFilteredPosts(filtered);
    }
  };

  const handleEnter = (key : string) => {
    if(key === "Enter"){
      handleSearch();
    }
  }

  return (
    <div className="SearchBox">
      <input
        className="SearchText"
        type="text"
        placeholder="기사 내용 검색 후 Enter를 눌러주세요."
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        onKeyDown={(e) => handleEnter(e.key)}
      />
      <img src={NewsRoomSearch} alt="Search Icon" onClick={handleSearch} />
    </div>
  );
};

export default Search;
