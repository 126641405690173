import React, { useEffect } from "react";
import { ModalDragon } from "../../asset/img/index";
interface NewsCheckModalProps {
    handleCloseModal: () => void;
    ModalDeleteClick: (title: any) => Promise<void>;
    title: string | null;
}

const NewsDeleteModal: React.FC<NewsCheckModalProps> = ({
    handleCloseModal,
    ModalDeleteClick,
    title
}) => {

    return (
        <div className="NewsCheckModal">
            <div className="NewsCheck">
                <span className="Error">정말 삭제하시겠습니까?</span>
                <span className="OneMore">진짜? 진짜???? 진짜로??????? 삭제됩니다??</span>
                <span className="Text">확인을 누르시면 영영 돌이킬 수 없습니다.</span>
                <div className="CheckBtn">
                    <button className="Ok"
                        onClick={() => ModalDeleteClick(title)}
                    >
                        확인
                    </button>
                    <button className="No" onClick={handleCloseModal}>
                        취소
                    </button>
                </div>
                <img className="ModalDragon" src={ModalDragon} alt="Dragon" />
            </div>
        </div>
    );
};

export default NewsDeleteModal;
