import React, { useEffect, useRef } from "react";
import {
    TeamBackPlus,
    TeamBackDelete,
    MobileDelete,
    MobilePlus
} from "../../asset/img/index";

const TeamCulture: React.FC = () => {
    const card1Ref = useRef<HTMLDivElement>(null);
    const card2Ref = useRef<HTMLDivElement>(null);
    const card3Ref = useRef<HTMLDivElement>(null);

    const addClickListeners = (cardElement: HTMLDivElement | null, flipper: () => void) => {
        if (cardElement) {
            cardElement.addEventListener("click", flipper);
        }
    };

    useEffect(() => {
        const card1Element = card1Ref.current;
        const card2Element = card2Ref.current;
        const card3Element = card3Ref.current;

        const flipper1 = () => {
            if (card1Element) {
                card1Element.style.transform = "rotateY(180deg)";
                addClickListeners(card1Element, innerFlipper1);
            }
        };

        const innerFlipper1 = () => {
            if (card1Element) {
                card1Element.style.transform = "rotateY(0deg)";
                addClickListeners(card1Element, flipper1);
                card1Element.removeEventListener("click", innerFlipper1);
            }
        };

        addClickListeners(card1Element, flipper1);

        const flipper2 = () => {
            if (card2Element) {
                card2Element.style.transform = "rotateY(180deg)";
                addClickListeners(card2Element, innerFlipper2);
            }
        };

        const innerFlipper2 = () => {
            if (card2Element) {
                card2Element.style.transform = "rotateY(0deg)";
                addClickListeners(card2Element, flipper2);
                card2Element.removeEventListener("click", innerFlipper2);
            }
        };

        addClickListeners(card2Element, flipper2);

        const flipper3 = () => {
            if (card3Element) {
                card3Element.style.transform = "rotateY(180deg)";
                addClickListeners(card3Element, innerFlipper3);
            }
        };

        const innerFlipper3 = () => {
            if (card3Element) {
                card3Element.style.transform = "rotateY(0deg)";
                addClickListeners(card3Element, flipper3);
                card3Element.removeEventListener("click", innerFlipper3);
            }
        };

        addClickListeners(card3Element, flipper3);

        return () => {
            if (card1Element) {
                card1Element.removeEventListener("click", flipper1);
                card1Element.removeEventListener("click", innerFlipper1);
            }
            if (card2Element) {
                card2Element.removeEventListener("click", flipper2);
                card2Element.removeEventListener("click", innerFlipper2);
            }
            if (card3Element) {
                card3Element.removeEventListener("click", flipper3);
                card3Element.removeEventListener("click", innerFlipper3);
            }
        };
    }, []);

    return (
        <div className="TeamCulture">
            <div className="TitleBox">
                <span className="TeamTitle">
                    우리가 만들어 가는 문화
                </span>
                <span className="ActiveTitle">
                    우리는 각자의 능력을 모아<br/>
                    하나가 되어 성취합니다.
                </span>
            </div>
            <div className="Top">
                <div ref={card1Ref} className="card">
                    <div className="FrontOne">
                        <span>
                            자율적인 환경에서<br />
                            체계적으로.
                        </span>
                        <img className="FrontPlus" src={TeamBackPlus} alt="Team Back Plus" />
                        <img className="MobileFrontPlus" src={MobilePlus} alt="Team Back Plus" />
                    </div>
                    <div className="BackOne">
                        <span>
                            우수한 의견은<br />
                            자율적인 환경에서<br />
                            효과적으로 드러납니다.<br />
                            이를 위해서는<br />
                            그러한 환경을 지원하고<br />
                            유지할 수 있는<br />
                            체계적인 문화를<br />
                            구축해야 합니다.
                        </span>
                        <img className="FrontDelete" src={TeamBackDelete} alt="Team Back Plus" />
                        <img className="MobileFrontDelete" src={MobileDelete} alt="Team Back Plus" />
                    </div>
                </div>
                <div ref={card2Ref} className="card">
                    <div className="FrontTwo">
                        <span>
                            안정적인 일정에서<br />
                            유동적으로.
                        </span>
                        <img className="FrontPlus" src={TeamBackPlus} alt="Team Back Plus" />
                        <img className="MobileFrontPlus" src={MobilePlus} alt="Team Back Plus" />
                    </div>
                    <div className="BackTwo">
                        <span>
                            보고와 공유가 지속되는<br />
                            공동의 업무 환경을<br />
                            추구하고 있습니다.<br />
                            원활한 공동체 환경을 위해서<br />
                            자주적으로 내용을 준비하고<br />
                            정리하는 자세를<br />
                            지향하고 있습니다.
                        </span>
                        <img className="FrontDelete" src={TeamBackDelete} alt="Team Back Plus" />
                        <img className="MobileFrontDelete" src={MobileDelete} alt="Team Back Plus" />
                    </div>
                </div>
                <div ref={card3Ref} className="card">
                    <div className="FrontThree">
                        <span>
                            공동적인 생활에서<br />
                            자주적으로.
                        </span>
                        <img className="FrontPlus" src={TeamBackPlus} alt="Team Back Plus" />
                        <img className="MobileFrontPlus" src={MobilePlus} alt="Team Back Plus" />
                    </div>
                    <div className="BackThree">
                        <span>
                            일정 준수는 동료 간 예의의<br />
                            일환으로 간주하기 때문에<br />
                            초기 일정을 체계적으로<br />
                            설정해야 합니다.<br />
                            그러나 불가피한 상황으로<br />
                            인해 일정이 변경될 가능성을<br />
                            항상 염두에 두어<br />
                            동료들의 혼란을<br />
                            최소화해야 합니다.
                        </span>
                        <img className="FrontDelete" src={TeamBackDelete} alt="Team Back Plus" />
                        <img className="MobileFrontDelete" src={MobileDelete} alt="Team Back Plus" />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TeamCulture;
