import React from "react";
import {
    ServiceArrow,
    SolutionArrow
} from "../../asset/img/index";
import { Link } from "react-router-dom";

const ServiceTab = () => {

    const scrollToTop = () => {
        window.scrollTo(0, 0);
    };

    const handleMenuClick = (section: string) => {
        scrollToTop();
    };


    return (
        <div className="ServiceTab">
            <div className="Tabls">
            <div className="SerSolTextBox">
                <span className="Service">
                    편리성을 위한 가치를 개발하고 있습니다
                </span>
                <span className="SolSerText">
                    혁신적인 기술과 전문가의 노하우로<br />
                    완벽한 솔루션과 서비스를 제공합니다.
                </span>
            </div>
            <div className="SolSerBox">
                <div className="btn-wrap">
                    <Link
                        className="button"
                        to="/detailSolution"
                        onClick={() => { handleMenuClick("") }}
                    >
                        <span className="button-overlay"></span>
                        <div className="SolutionBtn">
                            <div className="Left">
                                <span className="SolutionTopText">
                                    상세 솔루션
                                </span>
                                <span className="SolutionBtmText">
                                    Solution
                                </span>
                            </div>
                            <div className="Right">
                                <span className="SolutionRightText">
                                    솔루션 상세보기
                                </span>
                                <img className="TapNotImag" src={ServiceArrow} />
                            </div>
                        </div>
                    </Link>
                </div>
                <div className="btn-wrap">
                    <Link
                        className="button"
                        to="/detailService"
                        onClick={() => { handleMenuClick("") }}
                    >
                        <span className="button-overlay"></span>
                        <div className="ServiceTtn">
                            <div className="Left">
                                <span className="ServiceTopText">
                                    상세 서비스
                                </span>
                                <span className="ServiceBtmText">
                                    Serivce
                                </span>
                            </div>
                            <div className="Right">
                                <span className="ServiceRightText">
                                    서비스 상세보기
                                </span>
                                <img className="TapNotImag" src={ServiceArrow} />
                            </div>
                        </div>
                    </Link>
                </div>
            </div>
            </div>
        </div>
    )
}
export default ServiceTab;