import React, { useEffect, useState } from "react";
import { ManagerDeleteBack } from "../../asset/img/index";
import NewsDeleteModal from "../component/NewsDeleteModal";
import { Link, useNavigate } from "react-router-dom";
import NewsRoomGetPostsService from "../../service/NewsRoomService";
import { IPost } from "../../components/Community/NewsRoom";
import { NewsDelete } from "../../service/NewsRoomPostService";

const EditDeletPage = () => {
  const [showModal, setShowModal] = useState(false);
  const [posts, setPosts] = useState<IPost[]>([]);
  const [selectedPost, setSelectedPost] = useState<IPost | null>(null);

  const navigate = useNavigate();

  const handleDeleteClick = (post: IPost) => {
    setShowModal(true);
    setSelectedPost(post);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const convertDate = (createdAt: string) => {
    const today = new Date(createdAt);
    let year = today.getFullYear();
    let month = today.getMonth() + 1;
    let date = today.getDate();

    let todayDate = `${year}/${month}/${date}`;

    return todayDate;
  };

  const getPosts = async () => {
    try {
      const { data } = await NewsRoomGetPostsService();
      const sortedData =   data.sort(
        (a: IPost, b: IPost) =>
          new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
      )
      .reverse()
      setPosts(sortedData);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    const isLogin = localStorage.getItem("userName");
    if (!isLogin) {
      navigate("/FourchainsManagerLoginPage");
    }
    
    getPosts();
  }, []);

  const ModalDeleteClick = async (title: string) => {
    try {
      await NewsDelete({ title });
      getPosts();
      handleCloseModal();
    } catch (error) {
      console.error('Error handling delete click:', error);
    }
  };

  return (
    <div className="EditDelete">
      <img className="BackImg" src={ManagerDeleteBack} />

      <div className="NoticeBoard">
        <span className="Title">작성글 수정/삭제</span>
        <span className="Option">작성글 목록</span>

        <table className="Board">
          <thead>
            <tr className="Header">
              <th className="Number">no</th>
              <th className="WriteDay">작성날짜</th>
              <th className="Name">작성자</th>
              <th className="WriteTitle">제목</th>
            </tr>
          </thead>
          <tbody>
            {posts?.map((post: IPost, idx: number) => {
              return (
                <tr key={post.id} className="Data">
                  <td className="Number">{idx + 1}</td>
                  <td>{convertDate(post.createdAt)}</td>
                  <td>{post.writer}</td>
                  <td className="Mabe">
                    {post.title}
                    <div>
                      <Link to={"/FourchainsManagerNewsRoomPage"} state={post}>
                        <button className="Edit">수정</button>
                      </Link>
                      <button className="Delete" onClick={() => handleDeleteClick(post)}>
                        삭제
                      </button>
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      {showModal && <NewsDeleteModal title={selectedPost?.title || null} ModalDeleteClick={ModalDeleteClick} handleCloseModal={handleCloseModal} />}
    </div>
  );
};
export default EditDeletPage;