import React, { useEffect, useLayoutEffect, useState } from "react";
import { animateScroll as scroll } from "react-scroll";
import {
    HomeService,
    ServiceTab,
    ScrollButton
} from "../components/index";
import { useLocation } from "react-router-dom";


interface ServiceProps {
    setCurrentSection: React.Dispatch<React.SetStateAction<string>>;
}

const ServicePage: React.FC<ServiceProps> = ({ setCurrentSection }) => {
    const [currentPage, setCurrentPage] = useState(0);
    //여기에서 lastPage는 fullPageScroll LastPage입니다!!
    const [pageTitle, setPageTitle] = useState("");
    const lastPage = 1;

    useEffect(() => {
        const handleScroll = () => {
            let nextPage = Math.floor(window.scrollY / window.innerHeight);
            if (window.innerWidth < 1710) {
                nextPage = Math.floor(window.scrollY / window.innerHeight + 0.5);
            }
            setCurrentPage(nextPage);
        };

        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, [currentPage]);

    const handleWheel = (e: WheelEvent) => {
        const isScrollingDown = e.deltaY > 0;

        if (isScrollingDown && currentPage < lastPage) {
            // 마우스 휠을 아래로 스크롤할 때 다음 페이지로 이동
            scrollToNextPage();
        } else if (!isScrollingDown && currentPage < lastPage) {
            // 마우스 휠을 위로 스크롤할 때 이전 페이지로 이동
            scrollToPrevPage();
        }
    };

    const scrollToNextPage = () => {
        const nextPage = currentPage + 1;
        const offset = nextPage * window.innerHeight + 1;
        scroll.scrollTo(offset, {
            duration: 700,
            smooth: "easeInOutQuart",
        });
    };

    const scrollToPrevPage = () => {
        const prevPage = currentPage - 1;
        const offset = Math.max(0, prevPage * window.innerHeight);
        scroll.scrollTo(offset, {
            duration: 700,
            smooth: "easeInOutQuart",
        });
    };

    const location = useLocation();

    const scrollToElement = (elementId: string) => {
        const element = document.getElementById(elementId);
        if (element) {
            element.scrollIntoView({ behavior: "smooth" });
        }
    };

    useEffect(() => {
        const elementId = location.hash.substring(1);
        scrollToElement(elementId);
    }, [location]);


    useEffect(() => {
        // 이 컴포넌트가 마운트되면 휠 이벤트 리스닝
        window.addEventListener("wheel", handleWheel);

        // 컴포넌트 언마운트 시 이벤트 리스닝 정리
        return () => {
            window.removeEventListener("wheel", handleWheel);
        };
    }, [currentPage]);

    const [activeButton, setActiveButton] = useState('service1');

    useEffect(() => {
        setActiveButton(`service${currentPage + 1}`);
        switch (currentPage) {
            case 0:
                setPageTitle("Solution");
                break;
            case 1:
                setPageTitle("Product");
                break;
        }
    }, [currentPage]);

    const handleButtonClick = (elementId: string) => {
        scrollToElement(elementId);
        setActiveButton(elementId);
    };

    useEffect(() => {
        setCurrentSection("WhiteHeader");
        return () => {
        };
    }, [setCurrentSection]);

    return (
        <div className="Main">
            <section id="service1">
                <HomeService isfirstServicePage={currentPage === 0} />
            </section>
            <section id="service2">
                <ServiceTab />
            </section>
            <ScrollButton lastPage={2} sectionTitle="service" pageTitle={pageTitle} activeButton={activeButton} onButtonClick={handleButtonClick} />
        </div>
    );
};

export default ServicePage;