import React from "react";

const Ver2015 = () => {
    return (
        <div className="History2019">
            <div className="Years2019">
                2020
            </div>
            <div className="His2019">
                MCA 연구센터 창립
            </div>
        </div>
    )
}

export default Ver2015;