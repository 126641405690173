import React, { useState, useEffect } from "react";
import {
    Ver2020,
    Ver2021,
    Ver2022,
    Ver2023,
    Ver2024,
    MobileHistory
} from "../index";

interface HistoryProps {
    isHistoryPage: boolean;
}

const History: React.FC<HistoryProps> = ({ isHistoryPage }) => {
    const [selectedYear, setSelectedYear] = useState(2024);

    const blurAnimation = isHistoryPage
        ? 'blurUp 0.5s ease-in-out'
        : 'none';

    const handleYearClick = (year: any) => {
        setSelectedYear(year);
    };

    const renderVersionComponent = () => {
        switch (selectedYear) {
            case 2020:
                return <Ver2020 />;
            case 2021:
                return <Ver2021 />;
            case 2022:
                return <Ver2022 />;
            case 2023:
                return <Ver2023 />;
            case 2024:
                return <Ver2024 />;
            default:
                return null;
        }
    };

    return (
        <div className="HistoryVBer">
            <div className="History">
                <div className="LeftHis">
                    <div className="Investment">회사 연혁</div>
                    <div className="HistoryYears">
                        {[2024, 2023, 2022, 2021, 2020].map((year) => (
                            <button
                                key={year}
                                onClick={() => handleYearClick(year)}
                                style={{
                                    backgroundColor: selectedYear === year ? '#88A9A9' : 'rgba(255, 0, 0, 0)',
                                    color: selectedYear === year ? '#252525' : '#999999',
                                }}
                            >
                                {year}
                            </button>
                        ))}
                    </div>
                </div>
                <div className="RightHis">
                    <div className="HistoryName">히스토리</div>
                    <div
                        className="VersionContainer"
                        style={{
                            animation: blurAnimation,
                            opacity: isHistoryPage ? 1 : 0,
                        }}
                    >
                        {renderVersionComponent()}
                    </div>
                    <div
                    className="TabletVersionContainer"
                    style={{
                        animation: blurAnimation,
                    }}
                >
                    {renderVersionComponent()}
                </div>
                </div>
            </div>
            <div className="MobileHistory">
                <MobileHistory />
            </div>
        </div>
    );
}

export default History;
