import { useEffect, useMemo, useRef, useState } from "react";
import { CategoryArrow } from "../../asset/img";
import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";
import ImageResize from "quill-image-resize";
import { NewsCheckModal } from "../component/index";
import {
  NewsRoomEditService,
  NewsRoomPostService,
} from "../../service/NewsRoomPostService";
import { useLocation, useNavigate } from "react-router-dom";

Quill.register("modules/ImageResize", ImageResize);

const NewsRoomPost = () => {
  const { state: editData } = useLocation();
  const [categoryOpen, setCategoryOpen] = useState(false);
  const [form, setForm] = useState({
    category: "카테고리",
    creator: "",
    creatorEmail: "",
    title: "",
    image: null,
  });
  const [activeBtn, setActiveBtn] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [value, setValue] = useState("");
  const [isImgUpload, setIsImgUpload] = useState(false);
  const [error, setError] = useState({
    category: "카테고리 설정은 필수 항목입니다.",
    creator: "작성자 입력란은 필수 입력란입니다.",
    creatorEmail: "이메일 입력란은 필수 입력란입니다.",
    title: "제목 입력란은 필수 입력란입니다.",
    content: "상세내용 입력란은 필수 입력란입니다.",
  });

  const emailRegEx =
    /^(([^<>()\[\].,;:\s@"]+(\.[^<>()\[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;

  const navigate = useNavigate();

  // console.log(editData);

  useEffect(() => {
    const isLogin = localStorage.getItem("userName");
    if (!isLogin) {
      navigate("/FourchainsManagerLoginPage");
    }
  }, []);

  useEffect(() => {
    if (editData) {
      setForm({
        category: editData.category,
        title: editData.title,
        creator: editData.writer,
        creatorEmail: editData.writerE,
      });
      setValue(editData.contents);
      if (editData.imageUrl) {
        setIsImgUpload(true);
      }
      setError({
        category: "",
        creator: "",
        creatorEmail: "",
        title: "",
        content: "",
      });
    }
  }, [editData])

  const onClickCategoryBtn = () => {
    setCategoryOpen(!categoryOpen);
  };

  const onClickCategory = (category) => {
    setForm({ ...form, category: category });

    if (category !== "카테고리") setError({ ...error, category: "" });
    else setError({ ...error, category: "카테고리 설정은 필수 항목입니다." });

    setCategoryOpen(false);
  };

  let today = new Date();

  let year = today.getFullYear();
  let month = today.getMonth() + 1;
  let date = today.getDate();

  let todayDate = `${year}/${month}/${date}`;

  const modules = useMemo(() => {
    return {
      toolbar: {
        container: [
          [{ header: [1, 2, 3, 4, 5, false] }],
          ["bold", "blockquote"],

          [
            { list: "ordered" },
            { list: "bullet" },
            { indent: "-1" },
            { indent: "+1" },
          ],
          ["link", "image"],
          [{ align: [] }, { color: [] }, { background: [] }],
          ["clean"],
        ],
      },
      ImageResize: {
        modules: ["Resize", "DisplaySize"],
      },
    };
  }, []);

  const formats = [
    "header",
    "bold",
    "list",
    "bullet",
    "blockquote",
    "indent",
    "link",
    "image",
    "align",
    "color",
    "background",
  ];

  const formData = new FormData();

  const removeTag = (html) => {
    const content = new DOMParser().parseFromString(html, "text/html");
    return content.body.textContent || "";
  };

  const onChangeValue = (val) => {
    setValue(val);
    if (removeTag(val).length > 0) setError({ ...error, content: "" });
    else
      setError({ ...error, content: "상세내용 입력란은 필수 입력란입니다." });
  };

  const onChangeTitle = (e) => {
    setForm({ ...form, title: e.target.value });
    if (e.target.value.length > 0) setError({ ...error, title: "" });
    else setError({ ...error, title: "제목 입력란은 필수 입력란입니다." });
  };

  const onChangeCreator = (e) => {
    setForm({ ...form, creator: e.target.value });
    if (e.target.value.length > 0) setError({ ...error, creator: "" });
    else setError({ ...error, creator: "작성자 입력란은 필수 입력란입니다." });
  };

  const onChangeCreatorEmail = (e) => {
    setForm({ ...form, creatorEmail: e.target.value });
    if (e.target.value.length > 0) {
      setError({ ...error, creatorEmail: "" });
      if (emailRegEx.test(e.target.value))
        setError({ ...error, creatorEmail: "" });
      else
        setError({
          ...error,
          creatorEmail: "이메일 형식이 올바르지 않습니다.",
        });
    } else
      setError({
        ...error,
        creatorEmail: "이메일 입력란은 필수 입력란입니다.",
      });
  };

  const onChangeImage = (e) => {
    if (e.target.files.length > 0) {
      const file = e.target.files[0];
      const imageData = { image: file, imageName: file.name };
      setForm({ ...form, image: imageData });
      setIsImgUpload(true);
    } else {
      if (editData && editData?.imageUrl) {
        setIsImgUpload(true);
      } else setIsImgUpload(false);
    }
  };

  const quillRef = useRef();

  const onClickModal = () => {
    if (activeBtn) {
      setModalOpen(true);
      document.body.classList.add("modal-open");
    } else return;
  };

  const handleSubmit = async (data, formData) => {
    try {
      if (editData) {
        data = { ...data, id: editData.id };
        await NewsRoomEditService(data, formData);
      } else {
        await NewsRoomPostService(data, formData);
      }
      setForm({
        category: "카테고리",
        creator: "",
        creatorEmail: "",
        title: "",
        image: null,
      });
      setValue("");
      setIsImgUpload(false);
      inputRef.current.value = "";
      alert("글 작성이 완료됐습니다!");
      navigate("/FourchainsManagerFirstPage");
    } catch (error) {
      alert("글 작성에 실패했습니다. 다시 시도해 주세요.");
      console.error("실패", error);
    }
  };

  const onConfirm = () => {
    const data = {
      category: form.category,
      date: todayDate,
      writer: form.creator,
      writerE: form.creatorEmail,
    };

    formData.append("title", form.title);
    formData.append("contents", value);
    if (form.image) {
      formData.append("image", form.image.image);
      formData.append("imageName", form.image.imageName);
    }
    handleSubmit(data, formData);
    setModalOpen(false);
    document.body.classList.remove("modal-open");
  };

  const onCancel = () => {
    setModalOpen(false);
    document.body.classList.remove("modal-open");
  };

  const INPUT_LIST = [
    { title: "작성날짜", value: todayDate },
    { title: "작성자", value: form.creator, handler: onChangeCreator },
    {
      title: "작성자 이메일",
      value: form.creatorEmail,
      handler: onChangeCreatorEmail,
    },
  ];

  useEffect(() => {
    // 글 수정 시 error 메시지 뜨는 거 방지
    if(form.category!=="카테고리" && removeTag(value).length > 0 && form.title.length>0 && form.creator.length>0 && form.creatorEmail.length>0 && emailRegEx.test(form.creatorEmail)){
      setError({
        category: "",
        creator: "",
        creatorEmail: "",
        title: "",
        content: "",
      });
    }

    const isAllValid = Object.values(error).every((val) => val.length === 0);
    if (isAllValid && isImgUpload) {
      setActiveBtn(true);
    } else {
      setActiveBtn(false);
    }
  }, [form, isImgUpload, value]);

  const convertImgFileName = (fileName) => {
    return fileName.split("uploads/")[1];
  };

  const inputRef = useRef();

  return (
    <div className="NewsRoomPost">
      <div className="Blur">
        <div className="MainContainer">
          <div className="NewsRoomTitle">
            {editData ? "뉴스룸 수정" : "뉴스룸 작성"}
          </div>
          <div className="Container">
            <div className="LineContainer">
              <div className="InputContainer">
                <span className="InputTitle">카테고리</span>
                <div onClick={onClickCategoryBtn} className="Category">
                  <span>
                    {form.category}{" "}
                    <img src={CategoryArrow} onClick={onClickCategoryBtn} />
                  </span>
                  {categoryOpen && (
                    <div className="Dropdown">
                      <span onClick={() => onClickCategory("뉴스")}>뉴스</span>
                      <span onClick={() => onClickCategory("소식")}>소식</span>
                    </div>
                  )}
                </div>
              </div>
              {INPUT_LIST.map((list) => {
                return (
                  <div key={list.title} className="InputContainer">
                    <span className="InputTitle">{list.title}</span>
                    <input
                      value={list.value}
                      onChange={list.handler && list.handler}
                      className="Input"
                      placeholder="필수"
                      disabled={list.title === "작성날짜"}
                    />
                  </div>
                );
              })}
            </div>
            {error.category && (
              <span className="ErrorMessage">{error.category}</span>
            )}
            {error.creator && (
              <span className="ErrorMessage">{error.creator}</span>
            )}
            {error.creatorEmail && (
              <span className="ErrorMessage">{error.creatorEmail}</span>
            )}
            <div className="LineContainer">
              <div className="InputContainer">
                <span className="PostTitle InputTitle">제목</span>
                <input
                  className="PostTitleInput Input"
                  value={form.title}
                  onChange={onChangeTitle}
                  placeholder="필수"
                />
              </div>
            </div>
            {error.title && <span className="ErrorMessage">{error.title}</span>}
            <div>
              {editData &&
                `현재 이미지 파일명: ${convertImgFileName(editData.imageUrl)}`}{" "}
            </div>
            <input
              type="file"
              accept="image/*"
              ref={inputRef}
              onChange={onChangeImage}
            />
            {editData && <div className="ErrorMessage" style={{marginTop:5}}>* 이미지를 변경하고 싶으시다면 파일 선택을 눌러 새 이미지를 선택해 주세요.</div>}
            {!isImgUpload && (
              <div className="ErrorMessage">
                이미지 파일 업로드는 필수 항목입니다.
              </div>
            )}
            <div className="LineContainer">
              <div className="InputContainer">
                <span className="ContentTitle InputTitle">상세내용</span>
                <ReactQuill
                  style={{
                    width: "920px",
                    backgroundColor: "white",
                    borderRadius: "15px",
                  }}
                  modules={modules}
                  formats={formats}
                  value={value}
                  onChange={onChangeValue}
                  ref={quillRef}
                />
              </div>
            </div>
            {error.content && (
              <span className="ErrorMessage">{error.content}</span>
            )}
            <div className="BtnContainer">
              <div className={activeBtn ? "active" : ""} onClick={onClickModal}>
                작성 완료
              </div>
            </div>
            {modalOpen && (
              <NewsCheckModal
                form={form}
                value={value}
                date={todayDate}
                onConfirm={onConfirm}
                onCancel={onCancel}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewsRoomPost;
