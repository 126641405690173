import React from "react";

const Mobile2023 = () => {
    return (
        <div className="Mobile2023">
            <span className="MobileYears">
                2023
            </span>

            <div className="TextBox">
                <span className="BoldText">
                    한국은행 CBDC 활용성 테스트 사업자 선정<br />
                    (동형암호 파트, 영지식 증명 파트)<br />
                    2023 동북권 ICT이노베이션 스퀘어 확산 사업 및<br />
                    IR 챌린지 최우수상 수상<br />
                    우리금융그룹 디노랩 4기 선정<br />
                    2023 대구연구개발특구 액셀러레이팅 선정<br />
                    2023 KISA 개인정보 보호 활용 기술개발 스타트업 챌린지 선정<br />
                    제5회 팁스밋업 : 핀테크 선정<br />
                    KDB Next ONE 6기 선정<br />
                </span>
                <span className="Texts">
                    K-Camp 대구 4기 액셀러레이팅 선정<br />
                    KDB NextRound 선정 및 참여<br />
                    NextRise 2023 서울 스타트업 선정 및 참여<br />
                    하이파이브랩㈜와 업무협약(MOU) 체결<br />
                    씨엔티테크㈜ 로부터 투자 유치<br />
                    하이파이브랩㈜ 로부터 투자 유치
                </span>
            </div>
        </div>
    )
}
export default Mobile2023;