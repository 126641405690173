import React, { useState, useEffect } from "react";
import { HeaderLogo, BlackLogo, HeaderOpen, BlackOpen } from "../asset/img";
import { Link, useLocation } from "react-router-dom";

interface HeaderProps {
  currentSection: string;
  setCurrentSection: React.Dispatch<React.SetStateAction<string>>;
}

const Header: React.FC<HeaderProps> = ({ currentSection, setCurrentSection }) => {
  const location = useLocation();
  const [currentPage, setCurrentPage] = useState<string | undefined>(() => {
    // 페이지가 로딩될 때 현재 경로에서 currentPage를 추출하여 설정합니다.
    const pathSegments = location.pathname.split("/");
    const currentPageFromPath = pathSegments[pathSegments.length - 1];
    return currentPageFromPath || "";
  });

  const [prevScrollPos, setPrevScrollPos] = useState(0);

  const handlePageClick = (section: string) => {
    setCurrentPage(section);
  };

  const scrollToTop = () => {
    window.scrollTo(0, 0);
    setCurrentPage('');
  };

  const handleMenuClick = (section: string) => {
    setCurrentSection(section);
    setMenuOpen(false);
    scrollToTop();
  };

  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  useEffect(() => {
    // 페이지가 로딩될 때만 실행되도록 설정
    const pathSegments = location.pathname.split("/");
    const currentPageFromPath = pathSegments[pathSegments.length - 1];
    setCurrentPage(currentPageFromPath || "");
  }, [location.pathname]);

  const [headerVisible, setHeaderVisible] = useState(true);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.scrollY;
      const shouldHideHeader = currentScrollPos > prevScrollPos && currentScrollPos > 100;

      setHeaderVisible(!shouldHideHeader);
      setPrevScrollPos(currentScrollPos);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [prevScrollPos]);

  return (
    <div>
      <div className={`DeskTopHeader ${headerVisible ? '' : 'hidden'}`}>
        <div>
          <Link to="/" onClick={scrollToTop}>
            <img
              src={currentSection === "history" ? BlackLogo : HeaderLogo}
              alt="Header Logo"
            />
          </Link>
        </div>
        <div className="HeaderMenu">
          <Link
            to="/"
            onClick={() => {
              handleMenuClick("");
              handlePageClick('');
            }}
            style={{
              backgroundColor: currentPage === '' ? 'rgba(136, 169, 169, 0.20)' : undefined,
            }}
            className={`Menus ${currentSection === "history" ? "history-section" : ""}`}
          >
            <span>About</span>
          </Link>
          <Link
            to="/service"
            onClick={() => {
              handleMenuClick("");
              handlePageClick('service');
            }}
            style={{
              backgroundColor: currentPage === 'service' ? 'rgba(136, 169, 169, 0.20)' : undefined,
            }}
            className={`Menus ${currentSection === "history" ? "history-section" : ""}`}
          >
            <span>Service</span>
          </Link>
          <Link
            to="/culture"
            onClick={() => {
              handleMenuClick("");
              handlePageClick('culture');
            }}
            style={{
              backgroundColor: currentPage === 'culture' ? 'rgba(136, 169, 169, 0.20)' : undefined,
            }}
            className={`Menus ${currentSection === "history" ? "history-section" : ""}`}
          >
            <span>Team Culture</span>
          </Link>
          <Link
            to="/community"
            onClick={() => {
              handleMenuClick("");
              handlePageClick('community');
            }}
            style={{
              backgroundColor: currentPage === 'community' ? 'rgba(136, 169, 169, 0.20)' : undefined,
            }}
            className={`Menus ${currentSection === "history" ? "history-section" : ""}`}
          >
            <span>Community</span>
          </Link>
        </div>
      </div>

      <div className="TabletHeader">
        <div className="Logo">
          <Link to="/">
          <img
            src={currentSection === "history" ? BlackLogo : HeaderLogo}
            alt="Header Logo"
          />
          </Link>
          <img
            src={currentSection === "history" ? BlackOpen : HeaderOpen}
            className="dropbtn"
            onClick={toggleMenu}
          />
        </div>
        <div className={`dropdown-content ${menuOpen ? 'show' : ''} ${currentSection === "history" ? 'history-dropdown' : ''}`}>
          <Link
            to="/"
            onClick={() => handleMenuClick("")}
            className={`Link ${currentSection === "history" ? "history-Link" : ""}`}
          >
            About
          </Link>
          <Link
            to="/service"
            onClick={() => handleMenuClick("")}
            className={`Link ${currentSection === "history" ? "history-Link" : ""}`}
          >
            Service
          </Link>
          <Link
            to="/culture"
            onClick={() => handleMenuClick("")}
            className={`Link ${currentSection === "history" ? "history-Link" : ""}`}
          >
            Team Culture
          </Link>
          <Link
            to="/community"
            onClick={() => handleMenuClick("")}
            className={`Link ${currentSection === "history" ? "history-Link" : ""}`}
          >
            Community
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Header;
