import React, { useEffect, useState } from "react";

interface FirstCultureProps {
    isfirstculturePage: boolean;
}

const FirstCulture: React.FC<FirstCultureProps> = ({ isfirstculturePage }) => {

    const blurAnimation = isfirstculturePage
        ? 'MainSlogan 0.7s ease-in-out'
        : 'none';

    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        setLoaded(true);
    }, []);

    return (
        <div className={`FirstCulture ${loaded ? "loaded" : ""}`}>
            <div className="TextCulture"
                style={{
                    animation: blurAnimation,
                    opacity: isfirstculturePage ? 1 : 0,
                }}
            >
                <span className="TabName">
                    Team Culture
                </span>
                <div className="FCulHead">
                    시너지가 우리의 힘이 되고<br />
                    다양성이 성공을 이끕니다.
                </div>
                <div className="FCulBtm">
                    차이를 인정하는 모든 의견은 중요하며<br />
                    혁신이 번성하는 환경을 조성합니다.<br />
                    포체인스에서 우리의 협력 정신은<br />
                    성취의 기반을 이룹니다.
                </div>
            </div>
        </div>
    );
};

export default FirstCulture;