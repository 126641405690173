import React, { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import "react-quill/dist/quill.snow.css";

interface DetailNewsProps {
  setCurrentSection: React.Dispatch<React.SetStateAction<string>>;
}

const DetailNews: React.FC<DetailNewsProps> = ({ setCurrentSection }) => {
  const { state: data } = useLocation();

  const today = new Date(data?.createdAt);
  let year = today.getFullYear();
  let month = today.getMonth() + 1;
  let date = today.getDate();

  let todayDate = `${year}/${month}/${date}`;

  useEffect(() => {
    setCurrentSection("history");
    return () => {};
  }, [setCurrentSection]);

  return (
    <div className="DetailNews">
      <div className="NewsBox">
        <span className="NewsTitle">{data?.title}</span>
        <span className="MobileNewsName">
          {data?.writer} | {data?.writerE}
        </span>
        <span className="NewsDate">
          {data?.category} | {data && todayDate}
        </span>
        <img
          className="DetailImage"
          // src={`http://localhost:3001${data?.imageUrl}`}
          src={`https://four-chains.com/${data?.imageUrl}`}
          alt={data?.title}
        />
        <div className="ql-snow">
          <div
            className="ql-editor ContentsWrapper"
            dangerouslySetInnerHTML={{ __html: data?.contents }}
          ></div>
        </div>
        <div className="Btm">
          <span className="NewsName">
            {data?.writer} | {data?.writerE}
          </span>

          <div className="NewsAsk">
            <Link to="/Contact">
              <span>IR | PR 문의하기</span>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DetailNews;
